import React from 'react';
import { Toast } from 'react-bootstrap';
import './ToastComponent.scss';

const ToastComponent = (props) => {
  const { message, type, show, onClose } = props;

  //let iconClass, toastClass;
  let toastClass;
  if (type === 'success') {
    toastClass = 'success-txt';
  } else if (type === 'error') {
    toastClass = 'error-txt';
  } else if (type === 'warning') {
    toastClass = 'warning-txt';
  }

  return (
    <Toast onClose={() => onClose && onClose()} className={toastClass} show={show} delay={3000} autohide>
      <div className="tostcenter">
        <p>{message}</p>
      </div>
    </Toast>
  )
}

export default ToastComponent;
