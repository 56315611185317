const Constant = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    WEB_URL: "https://qiot-web.herokuapp.com/",
    API: {
        ADMIN_LOGIN: "auth/login",
        CHECK_TOKEN: "auth/checkResetLink",
        FORGOTPASSWORD: "auth/forgot-password",
        RESETPASSWORD: "auth/resetPassword",
        TOKENCHECK: "auth/tokenCheck",
        ADMIN_USERLIST: "admin/GetUserdetails",
        UPDATEPROFILE: "admin/UploadDocument",
        CHANGEPASSWORD: "auth/ChangePassword",
        NOTIFICATIONMESSAGE: "notification/",
        UPDATE_NOTIFICATIONMESSAGE: "notification/",
        PEAKFLOWREPORT: "admin/userreport",
        ASTHAMAREPORT: "admin/asthamaReport",
        FITNESSREPORT: "fitnessandstress/getgraphdata",
        STEROIDREPORT: "admin/steroidReport",
        UPDATEPROFILEDETAILS: "admin/updatePlanurl",
        UPLOADEDUCATIONPLAN: "admin/educationUrl",
        GETSINGLEUSERDETAILS: "admin/getSingleUserDetails",
        GETDIURINALVARIATION: "peakflow/diurinalVariation",
        CHANGE_PASSWORD: "admin/changePassword"
    },
    REGEX: {
        email: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
        SPECIAL_CHAR: /[!@#\$%\^\&*\)\(+=._-]/g,
        NUMBER: /[0-9]/g,
        Name: /^[ a-zA-Z]+$/i,
        // ALPHANUMERIC: /^"^[a-zA-Z0-9_]*$"/i,
        ALPHANUMERIC: /^[ a-zA-Z0-9\.\-\,]+$/i,
        UPPERCASE: /[A-Z]/,
        LOWERCASE: /[a-z]/,
        Url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
        commission: /[1-9]$|^[1-9][0-9]$|^(100)$/i,
        AMOUNT: /^[$0-9]*$/,
        NUMERIC: /^\d*\.?\d*$/,
    },
}

export default Constant