
import { lazy } from 'react';

const Login = lazy(() => import("./pages/Login/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword/ResetPassword"));
const ChangePassword = lazy(() => import("./pages/ChangePassword/ChangePassword"));
const UserListing = lazy(() => import("./pages/UserListing/UserListing"));
const Notification = lazy(() => import("./pages/Notification/Notification"));
const Verification = lazy(() => import("./pages/Verification/Verification"));


const routes = [

    {
        path: "/",
        component: Login,
        exact: true,
    },

    {
        path: "/forgotpassword",
        component: ForgotPassword,
        exact: true,
    },
    {
        path: "/resetpassword/:id",
        component: ResetPassword,
        exact: true,
    },
    {
        path: "/changepassword",
        component: ChangePassword,
        exact: true,
    },
    {
        path: "/dashboard",
        component: Dashboard,
        exact: true,
    },
    {
        path: "/userlisting/:id",
        component: UserListing,
        exact: true,
    },
    {
        path: "/notification",
        component: Notification,
        exact: true,
    },
    {
        path: "/Verification/:id",
        component: Verification,
        exact: true,
    }



]



export default routes