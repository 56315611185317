import React, { useState } from 'react';
import { PubNubProvider, PubNubConsumer } from 'pubnub-react';
import Notification from "react-web-notification";

import App from './App';
import { pubnub, addListener, subscribe } from "./config/pubnub";
import { getNotificationList } from "./state/ducks/notification/operations";
import { connect } from "react-redux";


function PubnubProvider(props) {
  let [notification, setNotification] = useState({
    title: '',
    options: {}
  })

  const notificationOptions = (description) => {
    return {
      tag: Date.now(),
      body: description,
      lang: "en",
      dir: "ltr"
    }
  }

  const setNotificationEnvironment = (client) => {
    addListener(client, (message) => {
      setNotification({
        title: message.title,
        options: notificationOptions(message.description)
      })
      props.getNotificationList()
    })
    // subscribe(client)
  };

  return (
    <PubNubProvider client={pubnub}>
      <PubNubConsumer>
        {client => setNotificationEnvironment(client)}
      </PubNubConsumer>
      <Notification
        timeout={5000}
        title={notification.title}
        options={notification.options}
      />
      <App />
    </PubNubProvider >
  );
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = {
  getNotificationList
};

export default connect(mapStateToProps, mapDispatchToProps)(PubnubProvider);
