import React,{Suspense} from 'react';
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import routes from "./routes";
import { hideToast } from "./state/ducks/utils/operations"
import Loader from "./components/Loading/Loading"
import ToastComponent from "./components/UI/ToastComponent"
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';

function App(props) {
  const { toast, hideToast, loader } = props;
  return (
    <div className="App" >
      <ToastComponent show={toast.show} message={toast.message} type={toast.type} onClose={hideToast} />
      <Loader show={loader} />
      <BrowserRouter>
        <Suspense fallback={<div>Loading... </div>}>
          <Switch>
            {routes.map((route, index) => {
              return <Route
                key={index}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            })}
          </Switch>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    toast: state.utils.toast,
    loader: state.utils.loader
  }
};

const mapDispatchToProps = {
  hideToast
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
