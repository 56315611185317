import PubNub from 'pubnub';
import StorageService from "../services/localstorage.service";
const uuid = PubNub.generateUUID();

export const pubnub = new PubNub({
    publishKey: 'pub-c-b8b511cf-8584-44b9-9da3-386998af5b67',
    subscribeKey: 'sub-c-59047088-0a08-11eb-b57f-02bb4b88d936',
    uuid: uuid
});

export const addListener = (client, callback) => {
    client && client.addListener({
        message: messageEvent => {
            callback && callback(messageEvent.message)
        },
    });
}

export const subscribe = (client) => {
    let user = StorageService.getItem("user")
    if (user) {
        let channel = user.id
        client && client.subscribe({ channels: [channel] });
    }
}
