// import { get } from "lodash";
import { combineReducers } from "redux";
import * as types from "./types";

import { createReducer } from "../../utils";
import JWTService from "./../../../services/jwt.service";
import StorageService from "./../../../services/localstorage.service";

const authReducer = createReducer(false)({
    [types.LOGOUT]: () => false,
    [types.AUTHENTICATED]: () => true,
    [types.AUTHORIZATION_FAILED]: () => {
        StorageService.removeItem('isAuthenticated');
        StorageService.removeItem('user');
        StorageService.removeItem('token');
        return false
    }
});

const initializeSessionReducer = createReducer(null)({
    [types.INITIALIZE]: (state, action) => {
        const user = action.payload.user;
        const tokens = action.payload.tokens;
        StorageService.setItem('isAuthenticated', true);
        StorageService.setItem('user', user);
        JWTService.saveToken(tokens);
        return { user, tokens }
    },
    [types.DESTROY]: () => {
        StorageService.removeItem('isAuthenticated');
        StorageService.removeItem('user');
        JWTService.destroyToken();
        return null;
    }
});

export default combineReducers({
    isAuthenticated: authReducer,
    session: initializeSessionReducer,
});
