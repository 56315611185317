import axios from "axios";
import { get } from "lodash";
//import { store } from "./../index";
import JwtService from "./../services/jwt.service";
//import { refreshToken } from "./../state/ducks/auth/operations";

const instance = axios.create({
     baseURL: process.env.REACT_APP_BASE_URL,
    // baseURL: 'https://api-joyclub-development.herokuapp.com/v1'
});

let isRefreshing = false;
let failedQueue = [];

// Process all failed request 
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error)
            prom.reject(error);
        else
            prom.resolve(token);
    });
    failedQueue = [];
};

instance.interceptors.request.use(config => {
    console.log("1");
    // if (JwtService.getToken())
    //     config.headers['authorization'] = JwtService.getToken();
    return config;
});

instance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        const responseStatus = get(error, "response.status", "")

        // If refresh token fails
        
        if (responseStatus === 401 && error.config.url.indexOf("refresh-token") != -1) {
            processQueue(error, null);
            isRefreshing = false;
            return Promise.reject(error);
        }

        if (responseStatus === 401 && error.config.url.indexOf("login") != -1) {
            return Promise.reject(error);
        }

        // Check if original request 
        if (responseStatus === 401 && !originalRequest._retry) {
            // Push all the failed request due to expired token in queue
            console.log("responseStatus",isRefreshing);
            if (isRefreshing) {
                return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
                    .then(token => {
                        console.log("token",token);
                        originalRequest.headers["authorization"] = "Token " + token;
                        return instance(originalRequest);
                    })
                    .catch(err => Promise.reject(err));
            }

            originalRequest._retry = true;
            isRefreshing = true;

            // Try to refresh token
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.REACT_APP_BASE_URL}auth/refresh-tokens`, { refreshToken: localStorage.getItem('refresh-token') })
                    /* 
                        On success save token, set headers and start processing 
                        previously failed requests with new token
                    */
                    .then(response => {
                        //setHeader(response.data.payload.tokens.access.token)
                        localStorage.setItem('token',response.data.payload.tokens.access.token)
                        localStorage.setItem('refresh-token',response.data.payload.tokens.refresh.token)
                        //axios.defaults.headers.common["Authorization"] = response.data.payload.tokens.access.token;
                        originalRequest.headers["authorization"] = response.data.payload.tokens.access.token;
                        //processQueue(null, response.data.payload.tokens.access.token);
                        //console.log("sdsd",resolve(axios(originalRequest)));
                        resolve(axios(originalRequest));
                    })
                    /* 
                        On error proccess old failed request with token value null
                        and redirect to respective authentication page
                    */
                    .catch(err => {
                        processQueue(err, null);
                        reject(err);
                        // router.push({ name: "signin" });
                    })
                    /* 
                        Finally set isRefreshing token to false in either success or failure
                    */
                    .finally(() => isRefreshing = false);
            });
        }

        return Promise.reject(error);
    }
);

/**
 * Set the default HTTP request headers
 */
// const setHeader = (token) => {
//     instance.defaults.headers.common["Authorization"] = token;
// }

export default instance
