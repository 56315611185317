const Message = {
    ERRORMESSAGE: {
        EMAILEMPTY: 'This field cannot be empty.',
        EMAILINVALID: 'Are you sure you entered the valid email address ?',
        PASSWORDEMPTY: 'This field cannot be empty.',
        NEWPASSOWRD: 'This field cannot be empty.',
        CONFIRMPASSWORD: 'This field cannot be empty.',
        CONFIRMINVALID: 'Both passwords must match',
        OLDEMPTY: 'This field cannot be empty.',
        BASELINEEMPTY: 'Baseline is required',
        FIRSTNAMEEMPTY: 'This field cannot be empty.',
        LASTNAMEEMPTY: 'This field cannot be empty.',
        ALFANUMINVALID: 'This field cannot contain special characters.',
    }
}

export default Message